import { MESSAGES } from '@/consts/messages'
import { getMyInfo } from '@/utils/auth/getMyInfo'
import { handleShare as handleShareClick } from '@/utils/share'
import useToast from '../useToast'

/**
 * url에서 기존 utm은 제거하고, 공유하기용 utm을 붙인 주소가 공유되도록 한다.
 * @returns handleShare 공유하기 onClick 함수
 */
const useShareWithUtm = () => {
  const { showToast } = useToast()

  const handleShare = async ({ bottom = 24 }: { bottom?: number } = {}) => {
    const myInfo = await getMyInfo()
    const url = new URL(window.location.href)
    const searchParams = url.searchParams

    Array.from(searchParams.keys()).forEach((key) => {
      if (key.startsWith('utm')) searchParams.delete(key)
    })

    searchParams.set('utm_source', 'lunitcare')
    searchParams.set('utm_medium', 'share')
    searchParams.set('utm_campaign', 'share_button')
    if (myInfo?.user_id) searchParams.set('rcode', myInfo.user_id)

    // 해시프래그먼트는 제거한다. (ex. 가이드 상세)
    url.hash = ''

    handleShareClick({
      url: `${url.toString()}`,
      toast: () => showToast({ title: MESSAGES.URL_COPIED, bottom }),
    })
  }

  return { handleShare }
}

export default useShareWithUtm
